import { useNavigate } from 'react-router';

import {
  FormOutlined,
  // UserOutlined
} from '@ant-design/icons';
import Logo from 'assets/img/HIARex_logo.png';
import { Button } from 'antd';
import { auth } from 'services/firebase';

const Sidebar = () => {
  const navigate = useNavigate();

  function handleLogoutClick() {
    auth.signOut();
    navigate('/login');
  }

  return (
    <div className="rounded-xl w-60 bg-white shadow-xl">
      <div className="flex flex-col justify-between p-2 h-full">
        <div className="py-6 mx-auto">
          <img
            src={Logo}
            alt="하이알렉스 로고"
            className="w-36 cursor-pointer"
            onClick={() => navigate('/')}
          />
        </div>
        <ul className="flex flex-col h-full gap-1">
          <li
            className="flex px-4 py-3 gap-6 text-slate-800 transition hover:bg-[#00AAFF] hover:text-white rounded cursor-pointer"
            onClick={() => navigate('/event')}
          >
            <div>
              <FormOutlined />
            </div>
            <div className="text-center">
              <span>이벤트 관리</span>
            </div>
          </li>
          {/* <li
            className="flex px-4 py-3 gap-6 text-slate-800 transition hover:bg-[#00AAFF] hover:text-white rounded cursor-pointer"
            onClick={() => navigate('/user')}
          >
            <div>
              <UserOutlined />
            </div>
            <div className="text-center">
              <span>계정 관리</span>
            </div>
          </li> */}
        </ul>
        <div className="mx-auto">
          <Button type="text" onClick={handleLogoutClick}>
            로그아웃
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
