import { useContext, useState } from 'react';
import { Button, Form, Input, Popconfirm, Radio, message } from 'antd';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';

import { instance } from 'apis';

import ThumbnailUpload from './ThumbnailUpload';

import { AuthContext } from 'context/AuthContext';

import 'react-datepicker/dist/react-datepicker.css';
import { ErrorModal } from 'components/common/Modal';
import { useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';

const EventRegister = () => {
  const context = useContext(AuthContext);

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const isLockValue = Form.useWatch('isLock', form);
  const [thumbnailUrl, setThumbnailUrl] = useState<string>('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const success = (content: string) => {
    message.success(content);
  };

  async function handleFormSubmit(values: any) {
    const addData = {
      content: {
        id: values.contentId,
        langCode: 'ko_KR',
        thumbnail: thumbnailUrl,
        title: values.title,
        description: values.description,
      },
      location: {
        latitude: values.latitude,
        longitude: values.longitude,
      },
      state: values.state,
      estimatedTime: values.estimatedTime,
      estimatedDistance: values.estimatedDistance,
      startDateTime: values.startDateTime ?? null,
      endDateTime: values.endDateTime ?? null,
      isSingle: values.isSingle,
    };

    await instance
      .post(`/web/events`, addData, {
        headers: {
          Authorization: context.token,
        },
      })
      .then(() => {
        success('이벤트 등록에 성공했습니다.');
        navigate('/event');
      })
      .catch((error) => {
        ErrorModal(error.response.data.errorCode, error.response.data.message);
      });
  }

  return (
    <div className="bg-white shadow-xl w-full rounded-xl">
      <div className="px-6 py-8">
        <div className="flex gap-2">
          <LeftOutlined
            className="text-gray-700 font-bold text-2xl"
            onClick={() => navigate(-1)}
          />
          <h5 className="text-4xl font-bold text-gray-700 font-GyeonggiCheonnyeon align-middle">
            이벤트 등록
          </h5>
        </div>
      </div>
      <div className="px-6 pb-6">
        <Form
          form={form}
          name="content-register"
          layout="vertical"
          className="grid grid-cols-6 grid-rows-7 gap-x-4"
          onFinish={handleFormSubmit}
          initialValues={{ state: 'OPEN', isSingle: true }}
        >
          <Form.Item
            name="thumbnail"
            className="col-span-2 row-span-5"
            label="썸네일"
            required
          >
            <ThumbnailUpload
              thumbnailUrl={thumbnailUrl}
              setThumbnailUrl={setThumbnailUrl}
            />
          </Form.Item>
          <Form.Item name="id" label="ID" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="contentId" label="콘텐츠 ID" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="title"
            label="이벤트명"
            rules={[
              {
                required: true,
                message: '이벤트명을 입력해주세요.',
              },
            ]}
            className="col-span-2"
          >
            <Input placeholder="이벤트명" />
          </Form.Item>
          <Form.Item name="state" label="상태" required>
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="OPEN">개시</Radio.Button>
              <Radio.Button value="CLOSE">종료</Radio.Button>
              <Radio.Button value="HIDE">숨김</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="estimatedTime"
            label="예상소요시간"
            className="col-start-3"
            rules={[
              {
                required: true,
                message: '예상소요시간을 입력해주세요.',
              },
            ]}
          >
            <Input suffix="hr" placeholder="예상소요시간" />
          </Form.Item>
          <Form.Item
            name="estimatedDistance"
            label="예상소요거리"
            rules={[
              {
                required: true,
                message: '예상소요거리를 입력해주세요.',
              },
            ]}
          >
            <Input suffix="km" placeholder="예상소요거리" />
          </Form.Item>
          <Form.Item
            name="latitude"
            label="위도"
            rules={[
              {
                required: true,
                message: '위도를 입력해주세요.',
              },
            ]}
          >
            <Input placeholder="위도" />
          </Form.Item>
          <Form.Item
            name="longitude"
            label="경도"
            rules={[
              {
                required: true,
                message: '경도를 입력해주세요.',
              },
            ]}
          >
            <Input placeholder="경도" />
          </Form.Item>
          <Form.Item name="startDateTime" label="시작일">
            <DatePicker
              locale={ko}
              selected={startDate}
              placeholderText="시작일을 선택해주세요."
              onChange={(date) => {
                setStartDate(date);
              }}
              dateFormat="yyyy-MM-dd a h:mm"
              showTimeSelect
              showYearDropdown
              className="border h-10 py-2 px-4 rounded-md border-[#d9d9d9] hover:border-[#4096ff] transition duration-200 focus:border-[#4096ff] outline-none"
            />
          </Form.Item>
          <Form.Item name="endDateTime" label="종료일">
            <DatePicker
              locale={ko}
              selected={endDate}
              placeholderText="종료일을 선택해주세요."
              onChange={(date) => {
                setEndDate(date);
              }}
              dateFormat="yyyy-MM-dd a h:mm"
              showTimeSelect
              showYearDropdown
              className="border h-10 py-2 px-4 rounded-md border-[#d9d9d9] hover:border-[#4096ff] transition duration-200 focus:border-[#4096ff] outline-none"
            />
          </Form.Item>
          <Form.Item name="isLock" label="이벤트 비공개" hidden>
            <Radio.Group buttonStyle="solid">
              <Radio.Button value={true}>비공개</Radio.Button>
              <Radio.Button value={false}>공개</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="password"
            label="비밀번호"
            hidden
            rules={[
              { required: isLockValue, message: '비밀번호를 설정해주세요.' },
            ]}
          >
            <Input.Password disabled={!isLockValue} />
          </Form.Item>
          <Form.Item name="externalLink" label="외부링크" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="url" label="프리뷰용 웹뷰링크" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="isSingle" label="마커노출" required>
            <Radio.Group buttonStyle="solid">
              <Radio.Button value={true}>이벤트마커</Radio.Button>
              <Radio.Button value={false}>POI마커</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="description"
            label="설명"
            className="col-span-4 row-span-2"
          >
            <Input.TextArea spellCheck={false} rows={5} placeholder="설명" />
          </Form.Item>
          <Form.Item className="col-span-6 flex justify-end">
            <div className="flex justify-end gap-4">
              <Popconfirm
                title="이벤트 등록 취소"
                description="취소하면 작성한 이벤트 데이터는 사라집니다. 그래도 괜찮으시겠습니까?"
                onConfirm={() => navigate(-1)}
                okButtonProps={{ danger: true }}
                okText="확인"
                cancelText="취소"
              >
                <Button danger>취소</Button>
              </Popconfirm>
              <Button htmlType="submit" type="primary" className="bg-[#1677ff]">
                등록
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default EventRegister;
