import { BrowserRouter, Route, Routes } from 'react-router-dom';

import MainLayout from 'components/layout/Layout';

import Login from 'routes/Login';

import EventManage from 'components/event/EventManage';
import EventRegister from 'components/event/EventRegister';
import EventEdit from 'components/event/EventEdit';

import POIEdit from 'components/event/poi/POIEdit';

import 'styles/index.css';
import POIRegister from 'components/event/poi/POIRegister';
import Main from 'components/Main';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Main />} />
          <Route path="/event" element={<EventManage />} />
          <Route path="/event/register" element={<EventRegister />} />
          <Route path="/event/:eventId" element={<EventEdit />} />
          <Route
            path="/event/:eventId/poi/register"
            element={<POIRegister />}
          />
          <Route path="/event/:eventId/poi/:poiId" element={<POIEdit />} />
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
