import { useState, useEffect } from 'react';
import { Image, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Event } from 'interface/common';
import { useNavigate } from 'react-router-dom';
import { defaultApi } from 'services/api.helpers';
import dayjs from 'dayjs';

interface EventListProps {
  data: Event[];
  isLoading: boolean;
}

const EventTable = ({ data, isLoading }: EventListProps) => {
  const navigate = useNavigate();

  const [tableHeight, setTableHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleResize = () => {
      setTableHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const columns: ColumnsType<Event> = [
    {
      title: '썸네일',
      dataIndex: 'contents',
      key: 'thumbnail',
      width: '10%',
      align: 'center',
      className: '!p-2',
      render(value) {
        return (
          <Image
            src={`${defaultApi}${value[1]?.thumbnail}`}
            alt={value}
            height={70}
          />
        );
      },
    },
    {
      title: '이벤트명',
      dataIndex: 'contents',
      key: 'contents',
      width: '16%',
      render(value, record) {
        return (
          <span
            onClick={() =>
              navigate(`/event/${record.id}`, {
                state: value,
              })
            }
            className="text-base font-medium cursor-pointer hover:underline "
          >
            {value[1].title}
          </span>
        );
      },
    },
    {
      title: '상태',
      dataIndex: 'state',
      key: 'state',
      width: '5%',
      align: 'center',
      render(value) {
        switch (value) {
          case 'OPEN':
            return (
              <Tag className="m-0" color="processing">
                개시
              </Tag>
            );
          case 'CLOSE':
            return (
              <Tag className="m-0" color="default">
                종료
              </Tag>
            );
          case 'HIDE':
            return (
              <Tag className="m-0" color="warning">
                숨김
              </Tag>
            );
          default:
            return (
              <Tag className="m-0" color="error">
                에러
              </Tag>
            );
        }
      },
    },
    {
      title: '설명',
      dataIndex: 'contents',
      key: 'description',
      className: 'text-base',
      // width: '27%',
      ellipsis: true,
      render(value) {
        return (
          <p className="text-ellipsis whitespace-normal overflow-hidden">
            {value[1]?.description}
          </p>
        );
      },
    },
    {
      title: 'POI',
      dataIndex: 'poiCount',
      key: 'poiCount',
      className: 'text-base',
      width: '5%',
      align: 'center',
    },
    {
      title: '시작일',
      dataIndex: 'startDateTime',
      key: 'startDateTime',
      className: 'text-base',
      width: '10%',
      align: 'center',
      render(value) {
        return dayjs(value).format('YYYY-MM-DD');
      },
    },
    {
      title: '종료일',
      dataIndex: 'endDateTime',
      key: 'endDateTime',
      className: 'text-base',
      width: '10%',
      align: 'center',
      render(value) {
        return dayjs(value).format('YYYY-MM-DD');
      },
    },
    {
      title: '마커 타입',
      dataIndex: 'isSingle',
      key: 'isSingle',
      className: 'text-base',
      width: '10%',
      align: 'center',
      render(value) {
        switch (value) {
          case true:
            return (
              <Tag className="m-0" color="processing">
                이벤트 마커
              </Tag>
            );
          case false:
            return (
              <Tag className="m-0" color="default">
                POI 마커
              </Tag>
            );
          default:
            return (
              <Tag className="m-0" color="error">
                에러
              </Tag>
            );
        }
      },
    },
  ];

  return (
    <Table
      loading={isLoading}
      columns={columns}
      dataSource={data}
      pagination={false}
      scroll={{ x: true, y: tableHeight > 705 ? tableHeight - 217 : undefined }}
    />
  );
};

export default EventTable;
