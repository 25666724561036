import React, { useContext, useState, useEffect } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { message, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { POI } from 'interface/common';
import { useNavigate, useParams } from 'react-router-dom';
import { instance } from 'apis';
import { AuthContext } from 'context/AuthContext';
import { ErrorModal } from 'components/common/Modal';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children?.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'move' }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

interface POITableProp {
  title: string;
  poiData: POI[];
  setPoiData: React.Dispatch<React.SetStateAction<POI[]>>;
}

const success = (content: string) => {
  message.success(content);
};

async function putPoiSequence(id: string, token: string, poiData: POI[]) {
  const poiSeqData = poiData.map((item) => {
    return { id: item.id, seq: item.seq };
  });

  await instance
    .put(
      `/web/poi/seq/${id}`,
      { poi: poiSeqData },
      {
        headers: {
          Authorization: token,
        },
      },
    )
    .then(() => {
      success('성공적으로 poi 순서를 변경했습니다.');
    })
    .catch((error) => {
      ErrorModal(error.response.data.errorCode, error.response.data.message);
    });
}

const POITable = ({ title, poiData, setPoiData }: POITableProp) => {
  const context = useContext(AuthContext);
  const { eventId } = useParams();
  const navigate = useNavigate();

  const [tableHeight, setTableHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleResize = () => {
      setTableHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const columns: ColumnsType<POI> = [
    {
      key: 'sort',
      align: 'center',
      width: '5%',
    },
    {
      title: '순서',
      dataIndex: 'seq',
      key: 'seq',
      align: 'center',
      width: '5%',
    },
    {
      title: 'POI명',
      dataIndex: 'contents',
      key: 'title',
      width: '15%',
      render(value, record) {
        return (
          <span
            onClick={() =>
              navigate(`/event/${eventId}/poi/${record.id}`, {
                state: title,
              })
            }
            className="text-base font-medium cursor-pointer hover:underline "
          >
            {value[1].title}
          </span>
        );
      },
    },
    {
      title: '콘텐츠 유형',
      dataIndex: 'contents',
      key: 'contents',
      width: '10%',
      align: 'center',
      render(value) {
        function contentsCategory(arUrl: string, audioUrl: string) {
          if (arUrl) {
            return 'AR';
          } else if (audioUrl) {
            return '오디오';
          } else {
            return '';
          }
        }
        return <p>{contentsCategory(value[1].arUrl, value[1].audioUrl)}</p>;
      },
    },
    {
      title: 'POI위경도',
      dataIndex: 'location',
      key: 'location',
      width: '15%',
      render(value) {
        return (
          <>
            <p>{value.latitude}</p>
            <p>{value.longitude}</p>
          </>
        );
      },
    },
    {
      title: '주소명',
      dataIndex: 'contents',
      key: 'addressName',
      width: '20%',
      render(value) {
        return <p>{value[1]?.addressName}</p>;
      },
    },
    {
      title: '주소',
      dataIndex: 'contents',
      key: 'address',
      width: '30%',
      render(value) {
        return <p>{value[1]?.address}</p>;
      },
    },
  ];

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setPoiData((previous) => {
        const activeIndex = previous.findIndex((i) => i.id === active.id);
        const overIndex = previous.findIndex((i) => i.id === over?.id);

        const array = arrayMove(previous, activeIndex, overIndex);

        const updatedPoiData = array.map((item, index) => {
          return { ...item, seq: index + 1 };
        });

        putPoiSequence(eventId, context.token, updatedPoiData);

        return updatedPoiData;
      });
    }
  };

  return poiData !== null ? (
    <div className="flex flex-col gap-4">
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={poiData?.map((i) => i.id)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: {
                row: Row,
              },
            }}
            rowKey="id"
            columns={columns}
            dataSource={poiData}
            pagination={false}
            rowClassName={'cursor-default'}
            scroll={{
              x: true,
              y: tableHeight > 249 ? tableHeight - 350 : undefined,
            }}
            // expandable={{
            //   expandRowByClick: true,
            //   expandedRowClassName: () => 'cursor-default',
            //   expandedRowRender: (record) => {
            //     console.log(record);
            //     return <ContentPOIEdit poiId={record.id} />;
            //   },
            //   // rowExpandable: (record) => record.id !== '2',
            // }}
          />
        </SortableContext>
      </DndContext>
    </div>
  ) : (
    <></>
  );
};

export default POITable;
