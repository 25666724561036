import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from 'context/AuthContext';
import { auth } from 'services/firebase';
import { instance } from 'apis';

import Sidebar from 'components/layout/Sidebar';
// import Header from 'components/layout/Header';
// import Footer from 'components/layout/Footer';

const MainLayout = () => {
  const [isLoading, setIsLoading] = useState(true);
  const context = useContext(AuthContext);
  const navigate = useNavigate();

  function checkLoggedIn() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken()
          .then((token) => {
            instance
              .get(`/users/${user.uid}`, {
                headers: {
                  Authorization: token,
                },
              })
              .then((result) => {
                setIsLoading(false);
                context.setIsLoggedIn(true);
                context.setToken(token);
                context.setUserData({
                  ...result.data,
                });
              })
              .catch((error) => {
                setIsLoading(false);
                console.log('instance error : ', error);
                navigate('/login');
              });
          })
          .catch((error) => {
            setIsLoading(false);
            console.log('getIdToken error : ', error);
            navigate('/login');
          });
      }
      setIsLoading(false);
      // navigate('/login');
    });
  }

  useEffect(() => {
    checkLoggedIn();
  }, []);

  interface WrapperProps {
    children: React.ReactNode;
  }

  const Wrapper = ({ children }: WrapperProps) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  return isLoading ? (
    <></>
  ) : (
    <Wrapper>
      <div className="flex bg-gray-200 p-4 gap-4 h-screen">
        <Sidebar />
        <div className="flex flex-col gap-4 w-full max-h-full">
          {/* <Header /> */}
          <Outlet />
          {/* <Footer /> */}
        </div>
      </div>
    </Wrapper>
  );
};

export default MainLayout;
