import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Radio,
  Segmented,
  Upload,
  UploadFile,
  message,
} from 'antd';
import { instance } from 'apis';
import Editor from 'components/common/Editor';
import { ErrorModal } from 'components/common/Modal';
import { AuthContext } from 'context/AuthContext';
import { LangCode, POIContents } from 'interface/common';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CDN, defaultApi } from 'services/api.helpers';
import axios from 'axios';

const POIEdit = () => {
  const { eventId, poiId } = useParams();
  const context = useContext(AuthContext);
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [contentsData, setContentsData] = useState<POIContents[]>([]);
  const [description, setDescription] = useState<string>('');
  const [selectedLangCode, setSelectedLangCode] = useState<LangCode>('ko_KR');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [prevPOIId, setPrevPOIId] = useState<string | null>(null);
  const [nextPOIId, setNextPOIId] = useState<string | null>(null);
  const location = useLocation();

  const selectedContentValue = Form.useWatch('selectedContent', form);
  const audioContentValue = Form.useWatch('audioContent', form);
  const isSingleValue = Form.useWatch('isSingle', form);

  // Upload 컴포넌트 커스텀 리퀘스트(파일명 인코딩 이슈)
  const customActionRequest = (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;

    if (fileList.length !== 0) {
      // 파일이 이미 있을때는 재업로드시 다시 로딩상태로 변경
      setFileList([
        {
          uid: '1',
          // 파일명 파싱 코드 by Sarc
          name: 'uploading...',
          status: 'uploading',
          url: ``,
          percent: 0,
        },
      ]);
    }
    const fmData = new FormData();
    const config = {
      headers: {
        Authorization: context.token,
        'content-type': 'multipart/form-data',
      },
      onUploadProgress: (event: any) => {
        // console.log((event.loaded / event.total) * 100);
        for (let i = 10; i < 100; i += 10) {
          // progress는 업로드중 계속터지는 이벤트라 10퍼센트에 한번씩 일어나서 로딩바를 수정하도록 수정
          if ((event.loaded / event.total) * 100 > i) {
            setFileList([
              {
                uid: '1',
                name: 'uploading...',
                status: 'uploading',
                url: ``,
                percent: (event.loaded / event.total) * 100,
              },
            ]);
          }
        }
        onProgress({ percent: (event.loaded / event.total) * 100 }, file);
      },
    };
    fmData.append('file', file, encodeURIComponent(file.name));
    axios
      .post(defaultApi + '/uploads', fmData, config)
      .then((res) => {
        // 폼 audioUrl 교체
        form.setFieldsValue({
          audioUrl: `${CDN}${res.data.url}`,
        });
        // 파일리스트 교체
        const _fileName =
          `${CDN}${res.data.url}` && `${CDN}${res.data.url}` !== ''
            ? decodeURIComponent(`${CDN}${res.data.url}`).substring(
                decodeURIComponent(`${CDN}${res.data.url}`).lastIndexOf('/') +
                  1,
              )
            : 'audio_file.mp3';
        setFileList([
          {
            uid: '1',
            // 파일명 파싱 코드 by Sarc
            name: _fileName,
            status: 'done',
            url: `${CDN}${res.data.url}`,
          },
        ]);

        message.success(`${_fileName} 파일 업로드 성공`);
        onSuccess(file);
        // console.log('response-', res);
      })
      .catch((err: any) => {
        const error = new Error('Some error');
        // 폼 audioUrl 교체
        form.setFieldsValue({
          audioUrl: '',
        });
        // 파일리스트 교체
        setFileList([
          {
            uid: '1',
            // 파일명 파싱 코드 by Sarc
            name: '업로드 실패!',
            status: 'error',
            response: '업로드 실패! 다시 시도해주세요.', // custom error message to show
            url: '',
          },
        ]);

        message.error(`파일 업로드 실패! 다시 시도해주세요.`);
        onError({ event: error, err });
      });
  };

  const selectLang = (langCode: LangCode) => {
    setSelectedLangCode(langCode);
    const langData = contentsData.filter((item) => item.langCode === langCode);
    setDescription(langData[0].description);
    console.log(langData);
    form.setFieldsValue({
      // 언어 변경시 컨텐츠 유형과 오디오 url까지 변경
      contentId: langData[0].id,
      title: langData[0].title,
      description: langData[0].description,
      addressName: langData[0].addressName,
      address: langData[0].address,
      arUrl: langData[0].arUrl,
      selectedContent:
        langData[0].audioUrl !== ''
          ? 'audio'
          : langData[0].arUrl !== ''
          ? 'ar'
          : 'text',
      audioContent: 'file',
      audioUrl: langData[0].audioUrl,
    });
    // 언어 변경시 파일 url교체
    if (!langData[0].audioUrl || langData[0].audioUrl === '') {
      setFileList([]);
    } else {
      setFileList([
        {
          uid: '1',
          // 파일명 파싱 코드 by Sarc
          name:
            langData[0].audioUrl && langData[0].audioUrl !== ''
              ? decodeURIComponent(langData[0].audioUrl).substring(
                  decodeURIComponent(langData[0].audioUrl).lastIndexOf('/') + 1,
                )
              : 'audio_file.mp3',
          status: 'done',
          url: langData[0].audioUrl,
        },
      ]);
    }
  };

  const success = (content: string) => {
    message.success(content);
  };

  const getPOIData = useMemo(() => {
    return (poiId: string) => {
      instance
        .get(`/web/poi/${poiId}`, {
          headers: {
            Authorization: context.token,
          },
        })
        .then((result) => {
          const data = result.data.result;
          setContentsData(data.contents);
          setDescription(data.contents[1].description);
          if (data.contents[1].audioUrl !== '') {
            setFileList([
              {
                // 파일명 파싱 코드 by Sarc
                uid: '1',
                name:
                  data.contents[1].audioUrl && data.contents[1].audioUrl !== ''
                    ? decodeURIComponent(data.contents[1].audioUrl).substring(
                        decodeURIComponent(
                          data.contents[1].audioUrl,
                        ).lastIndexOf('/') + 1,
                      )
                    : 'audio_file.mp3',
                url: data.contents[1].audioUrl,
              },
            ]);
          }
          setPrevPOIId(data.prevPOIId);
          setNextPOIId(data.nextPOIId);
          form.setFieldsValue({
            id: data.id,
            eventId: data.eventId,
            contentId: data.contents[1].id,
            title: data.contents[1].title,
            latitude: data.location.latitude,
            longitude: data.location.longitude,
            addressName: data.contents[1].addressName,
            address: data.contents[1].address,
            selectedContent:
              data.contents[1].audioUrl !== ''
                ? 'audio'
                : data.contents[1].arUrl !== ''
                ? 'ar'
                : 'text',
            arUrl: data.contents[1].arUrl,
            audioContent: 'file',
            audioUrl: data.contents[1].audioUrl,
            isSingle: data.isSingle,
          });
        })
        .catch((error) => {
          ErrorModal(
            error.response.data.errorCode,
            error.response.data.message,
          );
        });
    };
  }, [form]);

  useEffect(() => {
    if (poiId !== null) {
      getPOIData(poiId);
    }
  }, [getPOIData, poiId]);

  async function handleFormSubmit(values: any) {
    const content = {
      id: values.contentId,
      langCode: selectedLangCode,
      title: values.title,
      audioUrl: selectedContentValue === 'audio' ? values.audioUrl : '',
      arUrl: selectedContentValue === 'ar' ? values.arUrl : '',
      addressName: values.addressName ?? '',
      address: values.address ?? '',
      description: description,
    };
    const editData = {
      content: content,
      location: {
        latitude: values.latitude ?? '',
        longitude: values.longitude ?? '',
      },
      eventId: values.eventId,
    };

    await instance
      .put(`/web/poi/${values.id}`, editData, {
        headers: {
          Authorization: context.token,
        },
      })
      .then(() => {
        const updatedData = contentsData.map((item) => {
          if (item.id === values.contentId) {
            return { ...item, ...content };
          }
          return item;
        });
        setContentsData(updatedData);

        form.setFieldsValue({
          // 전송 이후 수정된 정보를 기반으로 컨텐츠 유형 변경
          selectedContent:
            editData?.content?.audioUrl !== ''
              ? 'audio'
              : editData?.content?.arUrl !== ''
              ? 'ar'
              : 'text',
        });

        success('수정이 완료됐습니다.');
      })
      .catch((error) => {
        ErrorModal(error.response.data.errorCode, error.response.data.message);
      });
  }

  async function handleDelete() {
    await instance
      .delete(`/web/poi/${poiId}`, {
        headers: {
          Authorization: context.token,
        },
      })
      .then(() => {
        success('POI를 삭제했습니다.');
        navigate(-1);
      })
      .catch((error) => {
        ErrorModal(error.response.data.errorCode, error.response.data.message);
      });
  }

  async function handleDataPaste(langCode: LangCode) {
    const values = form.getFieldsValue([
      'id',
      'title',
      'audioUrl',
      'arUrl',
      'addressName',
      'address',
    ]);

    const pasteData = {
      parentId: values.id,
      langCode: langCode,
      title: values.title,
      description: description,
      audioUrl: values.audioUrl,
      arUrl: values.arUrl,
      addressName: values.addressName,
      address: values.address,
    };

    await instance
      .put('/web/poi', pasteData, {
        headers: {
          Authorization: context.token,
        },
      })
      .then(() => {
        contentsData.forEach((item) => {
          if (item.langCode === langCode) {
            item.title = values.title;
            item.description = description;
            item.audioUrl = values.audioUrl;
            item.arUrl = values.arUrl;
            item.addressName = values.addressName;
            item.address = values.address;
          }
        });
        success(
          `영어 콘텐츠를 ${
            langCode === 'ja_JP' ? '일본어' : '중국어'
          } 콘텐츠로 복사했습니다.`,
        );
      })
      .catch((error) => {
        ErrorModal(error.response.data.errorCode, error.response.data.message);
      });
  }

  return (
    <div className="flex flex-col bg-white shadow-xl w-full max-h-full rounded-xl">
      <div className="px-6 py-8 flex justify-between">
        <div className="flex gap-6">
          <div className="flex gap-2">
            <LeftOutlined
              className="text-gray-700 font-bold text-2xl"
              onClick={() => navigate(`/event/${eventId}`)}
            />
            <div>
              <h5 className="text-4xl font-bold text-gray-700 font-GyeonggiCheonnyeon align-middle">
                POI 정보
              </h5>
              <p className="absolute text-lg text-gray-500 leading-8">
                {location.state}
              </p>
            </div>
          </div>
          <Segmented
            className="bg-amber-100"
            size="large"
            options={[
              {
                label: '한국어',
                value: 'ko_KR',
                className: 'px-4',
              },
              {
                label: '영어',
                value: 'en_US',
                className: 'px-4',
              },
              {
                label: '일본어',
                value: 'ja_JP',
                className: 'px-4',
              },
              {
                label: '중국어',
                value: 'zh_CN',
                className: 'px-4',
              },
            ]}
            value={selectedLangCode}
            onChange={(value: LangCode) => {
              selectLang(value);
            }}
          />
          {selectedLangCode === 'en_US' && (
            <div className="flex gap-2">
              <Button onClick={() => handleDataPaste('ja_JP')}>
                영어 → 일본어
              </Button>
              <Button onClick={() => handleDataPaste('zh_CN')}>
                영어 → 중국어
              </Button>
            </div>
          )}
        </div>
        <div className="flex gap-2">
          {prevPOIId !== null && (
            <Button
              type="text"
              className="flex items-center"
              onClick={() =>
                navigate(`/event/${eventId}/poi/${prevPOIId}`, {
                  state: location.state,
                })
              }
            >
              <LeftOutlined /> 이전 POI
            </Button>
          )}
          {nextPOIId !== null && (
            <Button
              type="text"
              className="flex items-center"
              onClick={() =>
                navigate(`/event/${eventId}/poi/${nextPOIId}`, {
                  state: location.state,
                })
              }
            >
              다음 POI <RightOutlined />
            </Button>
          )}
        </div>
      </div>
      <Form
        form={form}
        name="POI-Data"
        layout="vertical"
        className="flex flex-col max-h-full overflow-y-auto px-4 pb-4"
        onFinish={handleFormSubmit}
      >
        <div className="p-2 overflow-y-auto grid grid-cols-3 gap-x-4 border-y">
          <Form.Item name="id" label="ID" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="contentId" label="콘텐츠 ID" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="eventId" label="이벤트 ID" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="title"
            label="POI명"
            rules={[
              {
                required: true,
                message: 'POI명을 입력하세요.',
              },
            ]}
          >
            <Input placeholder="POI명" />
          </Form.Item>
          <Form.Item
            name="latitude"
            label="위도"
            hidden={isSingleValue}
            rules={[
              {
                required: !isSingleValue,
                message: '위도를 입력하세요.',
              },
            ]}
          >
            <Input placeholder="위도" />
          </Form.Item>
          <Form.Item
            name="longitude"
            label="경도"
            hidden={isSingleValue}
            rules={[
              {
                required: !isSingleValue,
                message: '경도을 입력하세요.',
              },
            ]}
          >
            <Input placeholder="경도" />
          </Form.Item>
          <Form.Item
            name="addressName"
            label="주소명"
            hidden={isSingleValue}
            className="col-start-1"
            rules={[
              {
                required: !isSingleValue,
                message: '주소명을 입력하세요.',
              },
            ]}
          >
            <Input placeholder="주소명" />
          </Form.Item>
          <Form.Item
            name="address"
            label="주소"
            hidden={isSingleValue}
            rules={[
              {
                required: !isSingleValue,
                message: '주소를 입력하세요.',
              },
            ]}
          >
            <Input placeholder="주소" />
          </Form.Item>
          <Form.Item name="selectedContent" label="컨텐츠 유형" required>
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="text">텍스트</Radio.Button>
              <Radio.Button value="ar">AR 가이드</Radio.Button>
              <Radio.Button value="audio">오디오 가이드</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="arUrl"
            label="AR 가이드(URL)"
            className="col-start-1"
            rules={[
              {
                required: selectedContentValue === 'ar',
                message: 'AR 가이드(URL)를 입력해주세요.',
              },
            ]}
            hidden={selectedContentValue !== 'ar'}
          >
            <Input placeholder="AR 가이드(URL)" />
          </Form.Item>
          <Form.Item
            label="오디오 가이드"
            hidden={selectedContentValue !== 'audio'}
            required={selectedContentValue === 'audio'}
            className="col-span-2"
          >
            <Form.Item name="audioContent" className="inline-block w-32">
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="file">파일</Radio.Button>
                <Radio.Button value="url">URL</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              hidden={audioContentValue !== 'file'}
              className={audioContentValue === 'file' && 'inline-block'}
            >
              {fileList.length !== 0 ? (
                // 파일이 이미 존재할 때
                <Upload
                  name="file"
                  customRequest={customActionRequest}
                  fileList={fileList}
                  className="flex gap-4"
                  onChange={(info) => {
                    if (info.file.status === 'removed') {
                      // 이미 업로드 된 파일을 삭제버튼 누른 경우
                      form.setFieldsValue({
                        // form에서 삭제하여 submit되지 않도록 조치
                        audioUrl: '',
                      });
                      setFileList([]); // 파일 리스트에서 비우기
                    }
                  }}
                  maxCount={1}
                >
                  <Button>가이드 재업로드</Button>
                </Upload>
              ) : (
                // 파일이 없을 때
                <Upload
                  name="file"
                  customRequest={customActionRequest}
                  className="flex gap-4"
                  maxCount={1}
                >
                  <Button>가이드 업로드</Button>
                </Upload>
              )}
            </Form.Item>
            <Form.Item
              name="audioUrl"
              hidden={audioContentValue !== 'url'}
              className={
                audioContentValue === 'url' &&
                'inline-block w-[calc(100%-128px)]'
              }
            >
              <Input placeholder="오디오 가이드 URL" />
            </Form.Item>
          </Form.Item>
          <Form.Item
            name="description"
            label="설명"
            className="col-start-1 col-span-3"
          >
            <Editor description={description} setDescription={setDescription} />
          </Form.Item>
          <Form.Item name="isSingle" label="마커노출" hidden>
            <Radio.Group buttonStyle="solid">
              <Radio.Button value={true}>이벤트마커</Radio.Button>
              <Radio.Button value={false}>POI마커</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </div>
        <div className="flex justify-end gap-4 pt-4">
          <Popconfirm
            title="이벤트 삭제"
            description="정말 이 POI를 삭제하시겠습니까?"
            onConfirm={handleDelete}
            okButtonProps={{ danger: true }}
            okText="삭제"
            cancelText="취소"
          >
            <Button danger>삭제</Button>
          </Popconfirm>
          <Button type="primary" htmlType="submit" className="bg-[#1677ff]">
            수정
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default POIEdit;
