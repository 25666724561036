import Resizer from 'react-image-file-resizer';

type CompressFormatType = 'JPEG' | 'PNG' | 'WEBP';

/**
 * 이미지 리사이즈 함수를 Promise로 변환합니다.(안 바꿀 파라미터는 null)
 * @param {Blob | string | File} file - 이미지 파일 (blob, base64, file. 여기서는 default: blob. antd input 기준 input.files[0])
 * @param {number|null} maxWidth - 최대 width(비율은 유지됨. null: 크기조절X, default: 480)
 * @param {number|null} maxHeight - 최대 height(비율은 유지됨. null: 크기조절X, default: 99999)
 * @param {CompressFormatType} compressFormat - 변환될 이미지 포맷
 * @param {number} quality - 이미지 변환 품질 0~100
 * @param {0|90|180|270|360} rotation - 이미지 회전 각도(0, 90, 180, 270, 360)
 * @param {string} outputType - response uri 타입(패키지에서의 default는 base64이나 현재 모듈에서 default는 blob, 우리 api에서 필요한 건 blob)
 * @param {number|null} minWidth - 최소 width(default null)
 * @param {number|null} minHeight - 최소 height(default null)
 * @return {Promise<FormData>} 리사이즈된 이미지 FormData
 */
export const imageResizer = async (
  file: Blob | string | File,
  maxWidth: number | null = 480,
  maxHeight: number | null = 99999,
  compressFormat: CompressFormatType = 'JPEG',
  quality: number = 75,
  rotation: 0 | 90 | 180 | 270 | 360 = 0,
  outputType: string = 'blob',
  minWidth: number | null = null,
  minHeight: number | null = null,
): Promise<FormData> => {
  return new Promise(async (resolve, reject) => {
    try {
      if (file && typeof file !== 'string') {
        const fileExtCode = ['PNG', 'JPEG', 'WEBP'].includes(
          extractExtension(file.name).toUpperCase(),
        )
          ? extractExtension(file.name).toUpperCase()
          : 'JPEG';
        Resizer.imageFileResizer(
          file,
          maxWidth || 480,
          maxHeight || 99999,
          fileExtCode || compressFormat || 'JPEG',
          quality || 75,
          rotation || 0,
          (uri: Blob | string | File) => {
            if (typeof uri !== 'string') {
              const prevImgSize = file.size;
              const newImgSize = uri.size;
              console.log(
                'prev(' +
                  prevImgSize +
                  '), new(' +
                  newImgSize +
                  ') = ' +
                  (100 * (prevImgSize - newImgSize)) / prevImgSize +
                  '% Compressed',
              );
            }

            // Blob to FormData
            let resultForm = new FormData();
            resultForm.append('image', uri);
            resultForm.append('type', 'image');
            resolve(resultForm);
          },
          outputType,
          minWidth,
          minHeight,
        );
      }
    } catch (err) {
      reject(err);
    }
  });
};

/**
 * extractExtension: imageHandler에서 사용되는 확장자 추출 함수
 * @param {string} fileName - 확장자가 포함된 파일명
 * @return {string} 확장자 혹은 ''
 */
function extractExtension(fileName: string): string {
  const reversedFileName = fileName.split('').reverse().join('');
  const firstDotIndex = reversedFileName.indexOf('.');
  if (firstDotIndex !== -1) {
    const extension = reversedFileName
      .slice(0, firstDotIndex)
      .split('')
      .reverse()
      .join('');
    return extension;
  } else {
    return '';
  }
}
