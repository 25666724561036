import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../services/firebase';

const Main = () => {
  const navigate = useNavigate();

  function checkLoggedIn() {
    auth.onAuthStateChanged((user) => {
      if (user) {
      } else {
        navigate('/login');
      }
    });
  }

  useEffect(() => {
    checkLoggedIn();
  }, []);

  return <div className="bg-white shadow-xl w-full rounded-xl">Welcome</div>;
};

export default Main;
