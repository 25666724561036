import ReactQuill from 'react-quill';
import React from 'react';
import PropTypes from 'prop-types';
import 'react-quill/dist/quill.snow.css';
import { CDN, defaultApi } from "../../services/api.helpers";
import axios from 'axios';
import {imageResizer} from '../../services/image-resize'
import {auth} from '../../services/firebase';

class Editor extends React.Component {
	// constructor(props) {
	// 	super(props);
	// }

	// quill 게시판용 이미지 핸들러
	imgHandler = function imageHandler() {
		const input = document.createElement('input');

		input.setAttribute('type', 'file');
		input.setAttribute('accept', 'image/*');
		input.click();

		input.onchange = async () => {
			const file = input.files[0];

			// 이미지 리사이징 적용 maxWidth 480 기준. 사용되는 파라미터는 imageResizer 함수 JSDock 주석 참조
			const _res = await imageResizer(file, 480).then(async res=>{
				// return res;
				// console.log('test1234-', res.get('image'));

				// Save current cursor state
				const _quill = this.quill;
				const cursorPosition  = _quill.getSelection().index;

				// 토큰 갱신 시작
				if (!!auth.currentUser) {
					await auth.currentUser.getIdToken(true).then(function(idToken) {
						const config = {
							headers: { Authorization: idToken },
						};
						axios.post(`${defaultApi}/images`, res, config).then((response) => {
							console.log('success');
							let _imgUrl = CDN + response.data.url;

							_quill.insertEmbed(cursorPosition, 'image', _imgUrl);
							_quill.setSelection(cursorPosition + 1);
						});
					});
				}
				// 토큰 갱신 끝
			});
		};
	};

	urlHandler = function urlHandler() {
		if (this.quill.getSelection().length === 0) {
			alert("링크가 삽입될 문구를 선택하고 다시 시도해 주세요.");
			return;
		}
		let url = prompt("URL주 소를 입력해주세요.");
		let _urlResult;

		function urlRegex(url) {
			return (/^([a-z][a-z0-9\*\-\.]*):\/\/(?:(?:(?:[\w\.\-\+!$&'\(\)*\+,;=]|%[0-9a-f]{2})+:)*(?:[\w\.\-\+%!$&'\(\)*\+,;=]|%[0-9a-f]{2})+@)?(?:(?:[a-z0-9\-\.]|%[0-9a-f]{2})+|(?:\[(?:[0-9a-f]{0,4}:)*(?:[0-9a-f]{0,4})\]))(?::[0-9]+)?(?:[\/|\?](?:[\w#!:\.\?\+=&@!$'~*,;\/\(\)\[\]\-]|%[0-9a-f]{2})*)?$/).test(url);
		}

		if (urlRegex(url)) { // http나 https가 붙은 url
			_urlResult = url;
		} else { // http나 https가 안붙은 url
			_urlResult = 'https://' + url;
		}

		this.quill.format('link',_urlResult);
	}

	vidHandler = function videoHandler() {
		let url = prompt('유투브 영상 주소를 입력해주세요.');

		function vidRegex1(_url) {
			return (_url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/));
		}

		function vidRegex2(_url) {
			return (_url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/));
		}

		function vidRegex3(_url) {
			return (_url.match(/^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/));
		}

		function vidRegex4(_url) {
			return (_url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/));
		}

		let match = vidRegex1(url) || vidRegex2(url) || vidRegex3(url);

		if (match && match[2].length === 11) {
			url = ('https') + '://www.youtube.com/embed/' + match[2] + '?showinfo=0';
		}
		if (match = vidRegex4(url)) { // eslint-disable-line no-cond-assign
			url = (match[1] || 'https') + '://player.vimeo.com/video/' + match[2] + '/';
		}

		let range = this.quill.getSelection();
		if (url != null) {
			this.quill.insertEmbed(range.index, 'video', url);
			this.quill.setSelection(range.index + 1);
		}
	};

	render() {
		const {description, setDescription} = this.props;

		let formats = [
			//'font',
			'size',
			// 'header',
			'bold', 'italic', 'underline', 'strike', 'blockquote',
			'image', 'link', 'video',
			'align', 'color', 'background',
		];

		let modules = {
			toolbar: {
				container: [
					//[{ 'font': [] }],
					[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
					// [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
					['bold', 'italic', 'underline', 'strike', 'blockquote'],
					// [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
					["link", "image", "video"],
					// ["image"],
						[{ 'align': [] }, { 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
					['clean'],
				],
				handlers: {
					image: this.imgHandler,
					video: this.vidHandler,
					link: this.urlHandler,
				},
			},
		};

		return (
			<div className="text-editor">
				<ReactQuill
					ref={el => {
						this.quill = el;
					}}
					theme="snow"
					modules={modules}
					formats={formats}
					style={{ height: '72vh'}}
					value={description}
					// onChange={this.onChange}
					onChange={(content, delta, source, editor) => setDescription(editor.getHTML())}
				/>
			</div>
		);
	}
}

Editor.propTypes = {
	description: PropTypes.string,     // desc 프로퍼티에 대한 유효성 검사
	setDescription: PropTypes.func,    // setDesc 프로퍼티에 대한 유효성 검사
}

export default Editor;
