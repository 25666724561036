import ReactDOM from 'react-dom/client';
import { AuthProvider } from 'context/AuthContext';
import { ConfigProvider } from 'antd';

import App from 'App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <AuthProvider>
    <ConfigProvider
      theme={{
        components: {
          DatePicker: { controlHeight: 40 },
          Radio: { controlHeight: 40 },
          Button: { controlHeight: 40 },
          Input: { controlHeight: 40 },
          Select: { controlHeight: 40 },
        },
        token: {
          fontFamily:
            "Pretendard, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif",
          fontSize: 16,
        },
      }}
    >
      <App />
    </ConfigProvider>
  </AuthProvider>,
);
