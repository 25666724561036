let defaultApi: string;
let CDN: string;

switch (window.location.hostname) {
  case 'localhost':
    defaultApi = 'https://api.hiarex.com';
    CDN = 'https://cdn.hiarex.com';
    break;
  case '127.0.0.1':
    defaultApi = 'https://api.hiarex.com';
    CDN = 'https://cdn.hiarex.com';
    break;
  default:
    defaultApi = 'https://api.hiarex.com';
    CDN = 'https://cdn.hiarex.com';
    break;
}

export { defaultApi, CDN };
